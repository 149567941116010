import React, { useEffect, useState, useMemo } from "react";

// 3rd party libraries
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";

import { Box, Typography, CircularProgress, Button, Link } from "@mui/material";

// assets
import mountains from "@/assets/mountains-c.jpg";
import logoNoBg from "@/assets/logo-no-bg.png";

// style imports
import { colors } from "@/styles/config";

// components
import Splash from "@/components/Splash";
import ThankYouSplash from "@/components/ThankYouSplash";
import OptInForm from "@/components/forms/OptInForm";
import OptOutForm from "@/components/forms/OptOutForm";
import { bhUserContactMap } from "@/helpers/user";

// styles
const useStyles = makeStyles((theme) => ({
  rightColumnContainer: {
    backgroundColor: "rgba(27, 68, 74, 0.75)",
    width: "40vw",
    height: "100vh",
    position: "relative"
  },
  leftContentContainer: {
    height: "100vh",
    overflowY: "scroll",
    boxSizing: "border-box"
  },
  logoContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  viewHeightCenteredBox: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  logo: {
    marginBottom: 0
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.darkGray
  },
  welcomeText: {
    alignSelf: "flex-start"
  },
  jobNameText: {
    color: colors.gray43,
    alignSelf: "flex-start"
    // fontWeight: "600",
    // fontSize: 12,
  },
  mainText: {
    marginTop: theme.spacing(3),
    alignSelf: "flex-start",
    lineHeight: 1.3
  },
  smallDisclaimerText: {
    color: colors.gray43,
    marginTop: theme.spacing(2),
    alignSelf: "flex-start",
    fontSize: 12
  },
  optButton: {
    width: 200,
    marginTop: theme.spacing(1)
  },
  optingFormJobTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    color: colors.gray43,
    textAlign: "center"
  },
  bgImg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "-1",
    top: 0,
    left: 0
  }
}));

export default function OptIn() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const { isMobile } = useSelector((state) => state.app);
  const { error, currentJob, loading } = useSelector((state) => state.jobs);

  const {
    submission,
    error: userError,
    loading: userLoading,
    submitting,
    submitted,
    optingIn,
    optingOut
  } = useSelector((state) => state.user);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const submissionId = query.get("submission");

  const [base64Resume, setBase64Resume] = useState(null);

  const [jobInitialized, setJobInitialized] = useState(false);

  const setOpting = (obj) => {
    dispatch.user.setOpting(obj);
  };
  //clear redux state on page change
  useEffect(() => {
    dispatch.user.purge();
  }, []);

  // empty dependency array means this will run on mount once
  useEffect(() => {
    // console.log("Bootstrapping opt in data for job # ", id);
    dispatch.user.setAnonymousToken();

    dispatch.jobs.startupOptIn({ id, submissionId }).then(() => setJobInitialized(true));
  }, [dispatch, id, submissionId]);

  const jobSubmissionMismatch = useMemo(() => {
    if (!submission) return false;

    const matching = submission && parseInt(id) === submission.jobOrder.id;

    return !matching;
  }, [id, submission]);

  const getVarinerContact = () => {
    if (!submission) return null;

    // default to Unassigned User in BH for now
    const sendingUser = submission.sendingUser || {
      id: 1,
      firstName: "Unassigned",
      lastName: "User"
    };

    let email = bhUserContactMap[sendingUser.id];
    let name = `${sendingUser.firstName} ${sendingUser.lastName}`;

    // if default, use Nick's things
    if (parseInt(sendingUser.id) === 1) {
      email = "nick@variner.com";
      name = "Variner";
    }

    return {
      email,
      name
    };
  };

  const varinerContact = submission ? getVarinerContact() : null;

  const transformBase64 = async (inputFile) => {
    return new Promise((resolve, reject) => {
      var fileReader = new FileReader();

      // If error occurs, reject the promise
      fileReader.onerror = () => {
        reject("Err");
      };

      // Define an onload handler that's called when file loaded
      fileReader.onload = () => {
        // File data loaded, so proceed to call setState
        if (fileReader.result !== undefined) {
          resolve(setBase64Resume(fileReader.result));
        } else {
          reject("Err");
        }
      };

      fileReader.readAsDataURL(inputFile);
    });
  };

  if (!submissionId) {
    return (
      <Box className={classes.viewHeightCenteredBox}>
        <Typography>
          No submission ID found in URL. Please contact your recruiter at Variner.
        </Typography>
        <Box className={classes.logoContainer}>
          <a href="https://www.variner.com">
            <img src={logoNoBg} style={{ maxHeight: 15 }} alt="variner logo" />
          </a>
        </Box>
      </Box>
    );
  }

  // show loading screen until all job content is captured
  if (!jobInitialized || userLoading) {
    return <Splash />;
  }

  // show loading screen until all job content is captured
  if (submitting) {
    return <Splash text={"Sending"} />;
  }

  // Do not show for dev submission ids
  const devSubmissions = [1481, 1310];

  if (submission && submission.customTextBlock1 && devSubmissions.indexOf(submission.id) < 0) {
    return (
      <ThankYouSplash
        header={"Thank you!"}
        Component={() => {
          return (
            <Typography>
              It looks like we`ve already received your opt-in responses for {currentJob.publicName}
              . If you have questions please reach out to{" "}
              <Link href={`mailto:${varinerContact.email}`} underline="hover">
                {varinerContact.name}
              </Link>
              .
            </Typography>
          );
        }}
      />
    );
  }

  if (submitted) {
    if (userError) {
      return (
        <ThankYouSplash
          header={"Something went wrong."}
          Component={() => {
            return (
              <Typography>
                We are so sorry for the inconvenience, but we were unable to save some information
                on our end. Please reach out to{" "}
                <Link href={`mailto:${varinerContact.email}`} underline="hover">
                  {varinerContact.name}
                </Link>{" "}
                to see what is missing from your response. Thank you.
              </Typography>
            );
          }}
        />
      );
    } else {
      const clientCorporationName = currentJob.clientCorporation
        ? currentJob.clientCorporation.name
        : "the hiring committee.";
      return (
        <ThankYouSplash
          header={"Thank you!"}
          text={
            optingIn
              ? `We’re excited to include you in this search process. We will be in touch as soon as we know more from ${clientCorporationName}.`
              : "We sincerely appreciate you taking the time to consider this opportunity. Please keep in touch."
          }
        />
      );
    }
  }

  if (jobSubmissionMismatch) {
    return (
      <Box className={classes.viewHeightCenteredBox}>
        <Typography
          style={{
            maxWidth: "50vw",
            textAlign: "center",
            marginBottom: 16
          }}
        >
          Sorry, something seems to be wrong with this URL. If you believe this notice is in error,
          please reach out to your contact at Variner.
        </Typography>
        <Link href="https://www.variner.com" underline="hover">
          Visit the Variner Website
        </Link>
      </Box>
    );
  }

  if (error || userError || !submission) {
    return (
      <Box className={classes.viewHeightCenteredBox}>
        <Typography style={{ maxWidth: "50vw", textAlign: "center" }}>
          Sorry, something went wrong. Try reloading the page. If you reach this error multiple
          times, please reach out to your contact at Variner.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ width: 200, marginTop: 16, marginBottom: 16 }}
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload Page
        </Button>
        <Link href="https://www.variner.com" underline="hover">
          Visit the Variner Website
        </Link>
      </Box>
    );
  }

  const optingStyles = {
    padding: isMobile ? 20 : 140,
    paddingTop: isMobile ? 20 : 40,
    marginTop: isMobile ? 70 : 110,
    width: isMobile ? "100vw" : "65vw"
  };

  const nonOptingStyles = {
    paddingLeft: isMobile ? 40 : 200,
    paddingRight: isMobile ? 40 : 350,
    width: isMobile ? "100vw" : "65vw"
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={optingIn ? "flex-start" : "center"}
      >
        <Box
          style={{
            alignSelf: "flex-start",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            boxSizing: "border-box",
            paddingTop: isMobile ? 20 : 16,
            paddingBottom: isMobile ? 20 : 16,
            paddingLeft: isMobile ? 40 : 140,
            backgroundColor: colors.white,
            width: isMobile ? "100%" : "65vw",
            boxShadow:
              optingIn || optingOut || isMobile ? "-1px 1px 4px 0px rgba(0, 0, 0, 0.15)" : "none"
          }}
        >
          <img className={classes.logo} src={logoNoBg} style={{ maxHeight: 20 }} alt="logo" />
        </Box>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={optingIn || optingOut ? "flex-start" : "center"}
          className={classes.leftContentContainer}
          style={optingIn || optingOut ? optingStyles : nonOptingStyles}
        >
          {(loading || userLoading) && <CircularProgress />}
          {!loading && !userLoading && (
            <Box style={{ maxWidth: 900 }}>
              {(optingIn || optingOut) && (
                <Box>
                  <Link
                    href="#"
                    onClick={() => setOpting({ optingIn: false, optingOut: false })}
                    underline="hover"
                  >
                    <Box display="flex" alignItems="center">
                      <ArrowBackIcon style={{ maxHeight: 15 }} />
                      <span>Back</span>
                    </Box>
                  </Link>
                  <Typography variant="h1" className={classes.optingFormJobTitle} width={"100%"}>
                    {`${currentJob.clientCorporation && currentJob.clientCorporation.name} - ${
                      currentJob.publicName
                    }`}
                  </Typography>
                </Box>
              )}
              <Box my={2}></Box>

              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                style={!optingIn && !optingOut ? { display: "flex" } : { display: "none" }}
              >
                <Typography variant="h1" className={classes.jobNameText}>
                  {`${currentJob.clientCorporation && currentJob.clientCorporation.name} - ${
                    currentJob.publicName
                  }`}
                </Typography>
                <Typography variant="h3" className={classes.mainText}>
                  Hi, {`${submission.candidate.firstName}`}. We appreciate your time and potential
                  interest in the {currentJob.publicName} role at{" "}
                  {currentJob.clientCorporation && currentJob.clientCorporation.name}. Please select
                  an option below to either continue in the process or pass on this opportunity.
                </Typography>
                <Typography className={classes.smallDisclaimerText}>
                  Not {`${submission.candidate.firstName} ${submission.candidate.lastName}`}? Please
                  contact{" "}
                  <Link href={`mailto:${varinerContact.email}`} underline="hover">
                    {varinerContact.name}
                  </Link>{" "}
                  for more information.
                </Typography>
                <Box my={2}></Box>
                <Box
                  width={"100%"}
                  my={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  flexDirection="column-reverse"
                >
                  <Button
                    variant="default"
                    color="secondary"
                    size="medium"
                    className={classes.optButton}
                    onClick={() => setOpting({ optingIn: false, optingOut: true })}
                  >
                    Not Interested
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className={classes.optButton}
                    onClick={() => setOpting({ optingIn: true, optingOut: false })}
                  >
                    Opt In
                  </Button>
                </Box>
              </Box>

              <Box style={optingIn ? { display: "block" } : { display: "none" }}>
                <Typography width={"100%"}>
                  Awesome! We`re glad you`re interested in this opportunity. As a next step, please
                  take the time to fill out the questions below. Keep in mind, these will be shared
                  with the hiring committee.
                </Typography>
                <Box my={3}></Box>
                <OptInForm
                  onFileChange={(files) => {
                    if (files && files[0]) {
                      transformBase64(files[0]);
                    } else {
                      setBase64Resume(null);
                    }
                  }}
                  onCancel={() => {
                    setOpting({ optingIn: false, optingOut: false });
                  }}
                  onSubmit={(values) => {
                    const formattedDate = moment().format("M_D_YY");

                    const data = {
                      status: "Confirmed Interest",
                      customTextBlock1: values.roleInterest,
                      customTextBlock2: values.locationInterest,
                      customDate2: moment(values.earliestStartDate).valueOf(),
                      customDate1: moment(values.idealStartDate).valueOf(),
                      customTextBlock3: values.relevantExperience
                    };

                    dispatch.user.opt({
                      id: submissionId,
                      jobId: id,
                      candidateId: submission.candidate.id,
                      data,
                      resumeContent: base64Resume,
                      responses: data,
                      jobTitle: currentJob.publicName,
                      clientCorporation: {
                        name: currentJob.clientCorporation.name
                      },
                      fileName: `${submission.candidate.firstName}${submission.candidate.lastName}_CV-job_${id}-${submissionId}-${formattedDate}.pdf`
                    });

                    // dispatch.user.optIn({
                    //   id: submissionId,
                    //   jobId: id,
                    //   candidateId: submission.candidate.id,
                    //   data,
                    // });
                    // dispatch.user.sendResume({
                    //   id: submissionId,
                    //   jobId: id,
                    //   candidateId: submission.candidate.id,
                    //   resumeContent: base64Resume,
                    //   responses: data,
                    //   jobTitle: currentJob.publicName,
                    //   clientCorporation: {
                    //     name: currentJob.clientCorporation.name,
                    //   },
                    //   fileName: `${submission.candidate.firstName}${submission.candidate.lastName}_CV-job_${id}-${submissionId}-${formattedDate}.pdf`,
                    // });
                  }}
                />
              </Box>

              <Box style={optingOut ? { display: "block" } : { display: "none" }}>
                <Typography width={"100%"}>
                  So we may better identify future opportunities for you, please briefly elaborate
                  why you’re not interested in this particular opportunity with a few short
                  sentences.
                </Typography>
                <Box my={3}></Box>
                <OptOutForm
                  onCancel={() => {
                    setOpting({ optingIn: false, optingOut: false });
                  }}
                  onSubmitOptOut={(values) => {
                    const data = {
                      status: values.optOutReason,
                      comments: values.optOutComments
                    };
                    dispatch.user.optIn({
                      id: submissionId,
                      jobId: id,
                      candidateId: submission.candidate.id,
                      data
                    });
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {!isMobile && (
        <Box className={classes.rightColumnContainer}>
          <Box className={classes.bgImg}>
            <img
              src={mountains}
              alt={"mountain"}
              style={{
                height: "100%",
                width: "auto",
                minHeight: "100%"
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
