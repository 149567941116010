/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import TableToolbar from "./TableToolbar";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters
} from "react-table";
import { IconButton, Typography, Box, Link, Button, Tooltip } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import _ from "lodash";

import TablePaginationActions from "./TablePaginationActions";
import { ReactComponent as CheckboxIcon } from "../../assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked-blue.svg";
import { ReactComponent as IndeterminateIcon } from "../../assets/icons/indeterminate-blue.svg";
import { colors } from "../../styles/config";
import ProgressBar from "../ProgressBar";
import SubmissionStatusSelect from "../SubmissionStatusSelect";
import { findStatusObjectIndex } from "@/helpers/statuses";
import { SelectColumnFilter } from "./filters";

const useStyles = makeStyles(() => ({
  table: {
    backgroundColor: "white"
  },
  grayText: {
    color: colors.gray43
  },
  currentRow: {
    backgroundColor: "rgba(26, 68, 74, 0.1)",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px"
  },
  currentRowCell: {
    fontWeight: "bold"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

// eslint-disable-next-line react/display-name, react/prop-types
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox
        ref={resolvedRef}
        icon={<CheckboxIcon />}
        checkedIcon={<CheckedIcon />}
        indeterminateIcon={<IndeterminateIcon />}
        {...rest}
      />
    </>
  );
});

const DefaultCell = ({ value }) => {
  return <Typography variant="subtitle2">{value}</Typography>;
};

// Set our default cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: DefaultCell
};

const CandidateSlateTable = ({
  activeStatusGroup,
  data,
  onOpenClick,
  presentationMode,
  selectedStatuses,
  setActiveStatusGroup,
  setSelectedStatuses,
  skipPageReset,
  sortedSubmissions,
  tableGroups,
  toggleDrawer,
  groupedSubmissions
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { submissions, resumeBook } = useSelector((state) => state.jobs);
  const [submissionsReportOpen, setSubmissionsReportOpen] = useState(false);
  const [submissionsBarFilter, setSubmissionsBarFilter] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const lastStatusRef = useRef();

  const handleOpenSubmissionsReport = useCallback(() => {
    lastStatusRef.current = selectedStatuses;
    setSubmissionsReportOpen(true);
  }, [selectedStatuses]);

  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return rowA.values[columnId].localeCompare(rowB.values[columnId]);
      }
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        width: presentationMode ? "12%" : "8%",
        hideable: true
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        width: presentationMode ? "12%" : "8%",
        hideable: true
      },
      {
        Header: "Undergrad",
        accessor: "undergradYear",
        width: "6%",
        hideable: true
      },
      {
        Header: "School",
        accessor: "undergradSchool",
        width: "10%",
        hideable: true,
        Cell: ({ row }) => {
          const value = row.values.undergradSchool;

          if (value && value.length > 25) {
            return (
              <Tooltip title={row.values.undergradSchool}>
                <Typography style={{ width: 150 }} noWrap={true} variant="subtitle2">
                  {row.values.undergradSchool}
                </Typography>
              </Tooltip>
            );
          }
          return (
            <Typography style={{ width: 150 }} noWrap={true} variant="subtitle2">
              {row.values.undergradSchool}
            </Typography>
          );
        }
      },
      {
        Header: "Email",
        accessor: "email",
        width: "15%",
        show: false,
        Cell: ({ row }) => {
          const value = row.original.email;
          if (!value) return null;

          return (
            <Link style={{ color: colors.varinerBlue }} href={`mailto:${value}`} underline="hover">
              {value}
            </Link>
          );
        },
        hideable: true
      },
      {
        Header: "Firm",
        accessor: "currentFirm",
        width: presentationMode ? "20%" : "16%",
        hideable: true,
        Cell: ({ row }) => {
          const value = row.values.currentFirm;

          if (value && value.length >= 22) {
            return (
              <Tooltip title={row.values.currentFirm}>
                <Typography style={{ width: 150 }} noWrap={true} variant="subtitle2">
                  {row.values.currentFirm}
                </Typography>
              </Tooltip>
            );
          }
          return (
            <Typography style={{ width: 150 }} noWrap={true} variant="subtitle2">
              {row.values.currentFirm}
            </Typography>
          );
        }
      },
      {
        Header: "GPA",
        accessor: "undergradGPA",
        width: "6%",
        hideable: true
      },
      {
        Header: "SAT-Math",
        accessor: "SATMath",
        width: "6%",
        show: false,
        hideable: true
      },
      {
        Header: "SAT-Verbal",
        accessor: "SATVerbal",
        width: "6%",
        show: false,
        hideable: true
      },
      {
        Header: "ACT",
        accessor: "ACT",
        width: "6%",
        show: false,
        hideable: true
      },
      {
        Header: "GMAT",
        accessor: "GMAT",
        width: "6%",
        show: false,
        hideable: true
      },

      // {
      //   Header: "Occupation",
      //   accessor: "occupation",
      //   width: presentationMode ? "20%" : "15%",
      // },
      {
        Header: "City",
        accessor: "city",
        width: "6%",
        hideable: true
      },
      {
        Header: "State",
        accessor: "state",
        width: "6%",
        hideable: true
      },
      {
        Header: () => {
          return (
            <Box display="flex">
              <span
                style={{
                  fontSize: 10,
                  color: colors.gray43,
                  fontWeight: "bold"
                }}
              >
                Status
              </span>
              {activeStatusGroup === "Reviewed" && (
                <Tooltip title="See reviewed status breakdown">
                  <span>
                    <IconButton
                      size="small"
                      disableRipple={true}
                      disableFocusRipple={true}
                      edge={"start"}
                      style={{
                        borderRadius: 3,
                        marginLeft: theme.spacing(0.5),
                        marginBottom: 1,
                        paddingBottom: 0,
                        paddingTop: 0
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOpenSubmissionsReport();
                      }}
                    >
                      <PollOutlinedIcon
                        style={{
                          width: theme.spacing(1.75),
                          height: theme.spacing(1.75),
                          color: colors.variner
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          );
        },
        sortType: (rowA, rowB, id) => {
          const rowAStatusObj = findStatusObjectIndex(rowA.values[id]);
          const rowBStatusObj = findStatusObjectIndex(rowB.values[id]);
          return rowAStatusObj - rowBStatusObj;
        },
        accessor: "status",
        width: "15%",
        Filter: SelectColumnFilter,
        filter: "includesSome",
        Cell: ({ row }) => {
          if (!row.original.status || !row.original.id) return null;

          const submissionId = row.original.id;
          const submission = submissions && submissions.find((s) => s.id === submissionId);

          if (!submission) return null;

          return (
            <SubmissionStatusSelect
              backgroundColor={colors.gray1}
              currentSubmission={submission}
              currentSubmissionId={submissionId}
            />
          );
        },
        hideable: true
      },
      {
        Header: "Owner",
        accessor: "owner",
        width: "10%",
        hideable: true,
        Cell: ({ row }) => {
          return (
            <Typography noWrap={true} variant="subtitle2">
              {row.values.owner}
            </Typography>
          );
        }
      },
      {
        Header: "Source",
        accessor: "source",
        width: "15%",
        hideable: true,
        show: false
      },
      {
        Header: "SO",
        accessor: "submissionOwnerGrade",
        width: "5%",
        hideable: true
      },
      {
        Header: "JO",
        accessor: "jobOwnerGrade",
        width: "5%",
        sortType: "alphanumericFalsyLast",
        hideable: true
      },
      {
        Header: "Scorecard",
        accessor: "score",
        width: "8%",
        Cell: ({ row }) => {
          if (!row.original.score) return null;

          return (
            <ProgressBar
              backgroundColor={"rgba(62, 159, 172, 0.3)"}
              labelStyle={{
                color: colors.black,
                fontSize: 12
              }}
              value={(row.original.score / 15) * 100}
            />
          );
        },
        hideable: true
      },
      {
        Header: "QA",
        accessor: "qa",
        width: "3%",
        Cell: ({ row }) => {
          const getBackgroundColor = () => {
            if (row.original.qa === 2) {
              return colors.varinerLight;
            }

            if (row.original.qa === 1) {
              return colors.varinerGold;
            }

            return colors.red;
          };

          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                marginLeft: "auto",
                width: 16,
                height: 16,
                borderRadius: "100%",
                backgroundColor: getBackgroundColor()
              }}
            >
              {row.original.qa === 2 && <CheckIcon style={{ fontSize: 12, color: "white" }} />}
              {row.original.qa === 1 && <RemoveIcon style={{ fontSize: 12, color: "white" }} />}
              {!!(row.original.qa === 0) && <CloseIcon style={{ fontSize: 12, color: "white" }} />}
            </Box>
          );
        },
        hideable: true
      }
    ],
    [activeStatusGroup, handleOpenSubmissionsReport, presentationMode, submissions, theme]
  );

  const selectionHook = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: "_selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
      ...columns
    ]);
  };
  const hooks = [
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    selectionHook
    // menuHook,
  ];

  const defaultFiltersForActiveStatusGroup = [
    {
      id: "status",
      value: tableGroups.find((group) => group.label === activeStatusGroup).statuses
    }
  ];

  const instance = useTable(
    {
      columns,
      data,
      sortTypes,
      defaultColumn,
      autoResetPage: !skipPageReset,
      autoResetFilters: false,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      stateReducer: (newState, action) => {
        if (action.type === "toggleAllRowsSelected" && action.value === "superfalse") {
          return {
            ...newState,
            selectedRowIds: {}
          };
        }
        return newState;
      },
      getRowId: (row) => {
        return row.id;
      },
      initialState: {
        pageSize: 50,
        sortBy: [
          {
            id: "status",
            desc: activeStatusGroup === "Reviewed" ? false : true
          },
          {
            id: "jobOwnerGrade",
            desc: false
          }
        ],
        filters: activeStatusGroup ? defaultFiltersForActiveStatusGroup : [],
        hiddenColumns: columns
          .map((column) => {
            if (column.show === false) {
              return column.accessor || column.id;
            } else {
              return null;
            }
          })
          .filter((c) => c)
      }
    },
    ...hooks
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    setFilter,
    filteredFlatRows,
    preGlobalFilteredRows,
    rows,
    setGlobalFilter,
    selectedFlatRows,
    visibleColumns,
    toggleHideColumn,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter }
  } = instance;
  const orderedSubmissions = useMemo(() => {
    const orderMap = new Map(rows.map((item, index) => [item.id, index]));
    return [...sortedSubmissions].sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id));
  }, [rows, sortedSubmissions]);
  // console.log("activeStatusGroup", activeStatusGroup);
  // console.log("filteredFlatRows", filteredFlatRows.length);

  const classes = useStyles();

  useEffect(() => {
    setPageSize(50);
  }, [setPageSize]);

  useEffect(() => {
    if (presentationMode) {
      toggleHideColumn("submissionOwnerGrade", true);
      toggleHideColumn("jobOwnerGrade", true);
      toggleHideColumn("source", true);
    } else {
      toggleHideColumn("submissionOwnerGrade", false);
      toggleHideColumn("jobOwnerGrade", false);
      toggleHideColumn("source", false);
    }
  }, [presentationMode, toggleHideColumn]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const handleSetSelectedStatuses = (data) => {
    setFilter("status", data);
    setSelectedStatuses(data);
  };

  const handleCloseSubmissionsReport = () => {
    setSubmissionsReportOpen(false);
    if (submissionsBarFilter) {
      setSubmissionsBarFilter(null);
      handleSetSelectedStatuses(lastStatusRef.current);
      lastStatusRef.current = null;
    }
  };

  const handleCloseSlateGenerationDialog = () => {
    dispatch.jobs.setResumeBook(null);
    setShowDialog(false);
  };

  const handleClearAll = () => {
    toggleAllRowsSelected("superfalse");
  };

  const numSelected = Object.keys(selectedRowIds).length;

  const getSelectedCandidatesArray = useCallback(() => {
    // return selectedRows.map((row) => row.original.id);
    return selectedRowIds && Object.keys(selectedRowIds).map((id) => parseInt(id));
  }, [selectedRowIds]);

  const compiledCsvData = useMemo(() => {
    const selectedCandidatesArray = getSelectedCandidatesArray();
    const selectedSubmissions =
      orderedSubmissions &&
      selectedCandidatesArray &&
      orderedSubmissions.filter(
        (submission) => selectedCandidatesArray.indexOf(submission.id) > -1
      );

    if (selectedSubmissions) {
      const possibleColumns = visibleColumns.filter(
        (column) => column.id.charAt(0) !== "_" && column.id !== "qa" && column.id !== "scorecard"
      );

      const columnKeys = possibleColumns.map((c) => _.startCase(c.id));
      const rowData = selectedSubmissions.map((submission) => {
        const foundTableSubmission = data.find((row) => row.id === submission.id);
        return Object.keys(foundTableSubmission)
          .filter((key) =>
            possibleColumns.find((c) => c.id === key) && key.charAt(0) !== "_" ? true : false
          )
          .map((key) => foundTableSubmission[key]);
      });

      return [columnKeys, ...rowData];
    } else {
      return [];
    }
  }, [visibleColumns, orderedSubmissions, getSelectedCandidatesArray]);

  // Render the UI for your table
  return (
    <TableContainer>
      <TableToolbar
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        handleCloseDialog={handleCloseSlateGenerationDialog}
        submissionsBarFilter={submissionsBarFilter}
        setSubmissionsBarFilter={(newFilter) => {
          if (!newFilter) {
            handleSetSelectedStatuses(lastStatusRef.current);
          }
          setSubmissionsBarFilter(newFilter);
        }}
        instance={instance}
        toggleDrawer={toggleDrawer}
        tableGroups={tableGroups}
        length={data && data.length}
        numSelected={numSelected}
        selectedRows={selectedFlatRows}
        selectedRowIds={selectedRowIds}
        compiledCsvData={compiledCsvData}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        selectedStatuses={selectedStatuses}
        handleSetSelectedStatuses={handleSetSelectedStatuses}
        activeStatusGroup={activeStatusGroup}
        setActiveStatusGroup={(newGroup) => {
          setActiveStatusGroup(newGroup);
          if (newGroup !== "Reviewed") {
            handleCloseSubmissionsReport();
          }
        }}
        handleCloseSubmissionsReport={handleCloseSubmissionsReport}
        sortedSubmissions={orderedSubmissions}
        groupedSubmissions={groupedSubmissions}
        filteredFlatRows={filteredFlatRows}
        submissionsReportOpen={submissionsReportOpen}
        handleClearAll={handleClearAll}
      />
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <TableRow key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerIndex) => (
                <TableCell
                  key={`${headerGroupIndex}-${headerIndex}`}
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                  size="small"
                  padding="checkbox"
                  className={classes.grayText}
                  active={column.isSorted ? "true" : "false"}
                  align={column.id === "qa" ? "right" : "left"}
                  style={{
                    width: column.width,
                    fontSize: 10
                  }}
                >
                  {/* {column.render("Header")} */}
                  {column.id.charAt(0) !== "_" ? (
                    <TableSortLabel
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      size="small"
                      direction={column.isSortedDesc ? "desc" : "asc"}
                      className={classes.grayText}
                      active={column.isSorted}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        <span className={classes.visuallyHidden}>
                          {column.isSortedDesc ? "sorted descending" : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    column.render("Header")
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {numSelected > 0 ? (
            <TableRow>
              <TableCell
                // padding="default"
                size="small"
                colspan={visibleColumns ? visibleColumns.length : 0}
                style={{
                  backgroundColor: colors.varinerBlue,
                  color: colors.white
                }}
              >
                <Box
                  style={{
                    float: "left",
                    paddingTop: 6
                  }}
                >
                  <Typography variant="subtitle2" color="secondary">
                    {`${numSelected} submission${numSelected > 1 ? "s" : ""} selected`}
                  </Typography>
                </Box>
                <Box style={{ float: "right" }}>
                  {resumeBook && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ width: "140px" }}
                      size="medium"
                      onClick={() => {
                        dispatch.jobs.setResumeBook(null);
                        // toggleAllRowsSelected(false);
                        window.open(resumeBook.signedUrl, "_blank");
                      }}
                    >
                      Open Slate
                    </Button>
                  )}
                  {!resumeBook && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{
                        width: "140px",
                        marginRight: theme.spacing(1)
                      }}
                      size="medium"
                      onClick={() => handleClearAll()}
                    >
                      Clear All
                    </Button>
                  )}
                  {!resumeBook && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ width: "140px" }}
                      size="medium"
                      onClick={() => setShowDialog(true)}
                    >
                      Generate Slate
                    </Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ) : null}
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  // console.log('cell', cell)
                  return (
                    <TableCell
                      key={index}
                      {...cell.getCellProps()}
                      padding="checkbox"
                      size="small"
                      style={{
                        width: cell.column.width,
                        paddingTop: theme.spacing(0.75),
                        paddingBottom: theme.spacing(0.75)
                      }}
                      onClick={(event) => {
                        const ignoreColumns = ["_selection", "status"];
                        if (
                          ignoreColumns.indexOf(cell.column.id) < 0 &&
                          event.target.tagName !== "A"
                        ) {
                          onOpenClick(row.original.id, event);
                        }
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            {data && data.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, { label: "All", value: data.length }]}
                style={{ borderBottom: "none" }}
                colSpan={visibleColumns ? visibleColumns.length : 0}
                count={filteredFlatRows ? filteredFlatRows.length : 0}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
  );
};

export default CandidateSlateTable;
