/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItemText,
  MenuItem,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Chip
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import LaunchIcon from "@mui/icons-material/Launch";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { CSVLink } from "react-csv";

import { ReactComponent as CheckboxIcon } from "../../assets/icons/checkbox.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked-blue.svg";
import { ReactComponent as IndeterminateIcon } from "../../assets/icons/indeterminate-blue.svg";
import { statuses } from "@/helpers/statuses";
import GlobalFilter from "./GlobalFilter";
import GroupTabButton from "./GroupTabButton";
import SettingsDrawer from "../SettingsDrawer";
import { colors } from "../../styles/config";
import { generateSlateData } from "../../helpers/slateData";
import ReviewedSubmissionsStatusBar from "../reports/ReviewedSubmissionsStatusBar";
import { toast } from "react-toastify";

const statusOptions = Object.keys(statuses).map((key) => statuses[key].bhLabel);

const slateOptions = [
  { id: "slateCover", label: "Include Cover Page" },
  { id: "summary", label: "Include Summary Page" },
  { id: "submissionQA", label: "Include QA Pages" },
  { id: "selectedOnly", label: "Selected Candidates Only" }
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  }
}));

const TableToolbar = ({
  tableGroups,
  numSelected,
  preGlobalFilteredRows,
  setGlobalFilter,
  globalFilter,
  selectedStatuses,
  activeStatusGroup,
  selectedRowIds,
  setActiveStatusGroup,
  sortedSubmissions,
  toggleDrawer,
  instance,
  compiledCsvData,
  groupedSubmissions,
  filteredFlatRows,
  handleSetSelectedStatuses,
  submissionsReportOpen,
  handleCloseSubmissionsReport,
  setSubmissionsBarFilter,
  submissionsBarFilter,
  setShowDialog,
  showDialog,
  handleCloseDialog,
  handleClearAll
}) => {
  const theme = useTheme();
  const classes = useToolbarStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams();
  const { presentationMode } = useSelector((state) => state.app);
  const { submissions, loading, submissionsLoading, currentJob, resumeBook } = useSelector(
    (state) => state.jobs
  );
  const [anchorEl, setAnchorEl] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedSlateOptions, setSelectedSlateOptions] = React.useState([
    "slateCover",
    "summary",
    "submissionQA",
    "selectedOnly"
  ]);

  const handleOpenSearch = () => {
    setSearchOpen(true);
  };

  const handleCloseSearch = () => {
    setSearchOpen(false);
  };

  const handleRefreshSubmissions = useCallback(async () => {
    try {
      await dispatch.jobs.fetchSubmissions({
        id,
        status: statusOptions
      });
    } catch (error) {
      toast.error("There was an error getting submissions.");
    }
  }, [dispatch.jobs]);

  const getSelectedCandidatesArray = () => {
    // return selectedRows.map((row) => row.original.id);
    return Object.keys(selectedRowIds).map((id) => parseInt(id));
  };

  const handleOpenGenerateSlate = (target, sortedSubmissions) => {
    // First, make sure selected only is an actual option
    const selectedCandidatesArray = getSelectedCandidatesArray();
    const visibleSelectedCandidates = sortedSubmissions.filter(
      (submission) => selectedCandidatesArray.indexOf(submission.id) > -1
    );
    const selectedOnlyAllowed = visibleSelectedCandidates.length > 0;

    if (!selectedOnlyAllowed && selectedSlateOptions.indexOf("selectedOnly") > -1) {
      const newSelectedSlateOptions = selectedSlateOptions.filter((opt) => opt !== "selectedOnly");
      setSelectedSlateOptions(newSelectedSlateOptions);
    }

    // then after determining if you can use selected submissions only, set the anchor el to open
    setAnchorEl(target);
  };

  const handleGenerateRequest = (sortedSubmissions) => {
    const selectedCandidatesArray = getSelectedCandidatesArray();
    const selectedOnly = selectedSlateOptions.indexOf("selectedOnly") > -1;

    const filteredSubmissions = sortedSubmissions.filter(
      (submission) => selectedCandidatesArray.indexOf(submission.id) > -1
    );

    const selectedSubmissions = selectedOnly ? filteredSubmissions : sortedSubmissions;
    const data = generateSlateData({
      submissions: selectedSubmissions,
      currentJob,
      selectedSlateOptions
    });

    dispatch.jobs.generateResumeBook(data);
    handleClearAll();
  };

  const isSlateOptionSelected = (id) => selectedSlateOptions.indexOf(id) !== -1;

  const handleSlateOptionClick = (event, id) => {
    const selectedIndex = selectedSlateOptions.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSlateOptions, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSlateOptions.slice(1));
    } else if (selectedIndex === selectedSlateOptions.length - 1) {
      newSelected = newSelected.concat(selectedSlateOptions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSlateOptions.slice(0, selectedIndex),
        selectedSlateOptions.slice(selectedIndex + 1)
      );
    }

    setSelectedSlateOptions(newSelected);
  };

  return (
    <Toolbar className={clsx(classes.root)}>
      <Dialog onClose={handleCloseDialog} open={showDialog} fullWidth={true} maxWidth={"sm"}>
        <IconButton
          size="small"
          color="inherit"
          onClick={handleCloseDialog}
          style={{
            alignSelf: "flex-end",
            margin: 8,
            position: "absolute",
            color: colors.gray43
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        {!resumeBook && (
          <DialogContent style={{ paddingTop: theme.spacing(3) }}>
            <Typography variant="subtitle1">Candidate Slate Options</Typography>
            <List>
              {slateOptions.map((option) => {
                const isItemSelected = isSlateOptionSelected(option.id);

                const disabled =
                  option.id === "selectedOnly" ? !numSelected || numSelected === 0 : false;

                return (
                  <MenuItem
                    value={option.id}
                    key={option.id}
                    style={{ padding: 0 }}
                    disabled={disabled}
                    onClick={(event) => handleSlateOptionClick(event, option.id)}
                  >
                    <Checkbox
                      size="small"
                      checked={isItemSelected}
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedIcon />}
                      indeterminateIcon={<IndeterminateIcon />}
                    />
                    <ListItemText
                      primary={<Typography variant="body2">{option.label}</Typography>}
                    />
                  </MenuItem>
                );
              })}
            </List>
            <DialogActions>
              <Button
                color="primary"
                size="medium"
                onClick={() => {
                  setAnchorEl(null);
                  handleGenerateRequest(sortedSubmissions);
                }}
                disabled={selectedStatuses && selectedStatuses.length ? false : true}
              >
                Generate
              </Button>
            </DialogActions>
          </DialogContent>
        )}
        {resumeBook && resumeBook.signedUrl && (
          <Box py={4} px={4} display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="subtitle1"
              style={{ paddingBottom: theme.spacing(3), width: "100%" }}
            >
              Successfully Generated Candidate Slate!
            </Typography>
            <Typography variant="body2" style={{ paddingBottom: theme.spacing(1), width: "100%" }}>
              {currentJob.clientCorporation.name} {currentJob.publicName}
            </Typography>
            <Typography variant="body2" style={{ paddingBottom: theme.spacing(3), width: "100%" }}>
              Click below to open slate PDF in a new page. This URL will be available for 24 hours,
              after which you`ll just need to create a new candidate slate.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "100%"
              }}
              href={resumeBook.signedUrl}
              target="_blank"
            >
              Open Slate PDF
            </Button>
          </Box>
        )}
      </Dialog>
      <Box
        style={{
          width: "calc(100% - 4px)",
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2)
        }}
      >
        {submissionsReportOpen && (
          <Paper elevation={2}>
            <Box
              p={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="subtitle1" style={{ marginLeft: theme.spacing(2) }}>
                Reviewed Status Breakdown
              </Typography>
              <IconButton
                size="small"
                onClick={handleCloseSubmissionsReport}
                style={{ marginRight: theme.spacing(2) }}
              >
                <CloseIcon
                  style={{
                    height: theme.spacing(2.5),
                    width: theme.spacing(2.5)
                  }}
                />
              </IconButton>
            </Box>
            {submissionsBarFilter && (
              <Chip
                size="small"
                icon={null}
                label={submissionsBarFilter}
                onClick={() => {
                  setSubmissionsBarFilter(null);
                }}
                onDelete={() => {
                  setSubmissionsBarFilter(null);
                }}
                variant="outlined"
                style={{ marginLeft: theme.spacing(2) }}
              />
            )}
            <ReviewedSubmissionsStatusBar
              filteredSubmissions={submissions.filter((submission) =>
                filteredFlatRows.find((row) => row.original.id === submission.id)
              )}
              onBarClick={(barData) => {
                if (!submissionsBarFilter) {
                  setSubmissionsBarFilter(barData.id);
                  handleSetSelectedStatuses([barData.id]);
                }
              }}
            />
          </Paper>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex">
          {tableGroups.map((group) => {
            return (
              <GroupTabButton
                key={group.label}
                length={
                  groupedSubmissions && groupedSubmissions[group.label]
                    ? groupedSubmissions[group.label].length
                    : "N/A"
                }
                selectedRowIds={selectedRowIds}
                groupedSubmissions={groupedSubmissions ? groupedSubmissions[group.label] : []}
                numSelected={numSelected}
                label={group.label}
                active={activeStatusGroup === group.label}
                statusOptions={group.statuses}
                onClick={(statusesSelected) => {
                  setActiveStatusGroup(group.label);
                  handleSetSelectedStatuses(statusesSelected);
                }}
                handleSetSelectedStatuses={handleSetSelectedStatuses}
                setActiveStatusGroup={setActiveStatusGroup}
              />
            );
          })}
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
            <Tooltip title="Refresh submissions list">
              <span>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={handleRefreshSubmissions}
                  className="icon-button__backgrounded"
                  style={{
                    padding: theme.spacing(0.5),
                    borderRadius: 5,
                    marginRight: theme.spacing(1),
                    height: 36,
                    width: 36
                  }}
                >
                  <CachedIcon
                    fontSize="small"
                    disabled={loading || submissionsLoading ? true : false}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Box display="flex">
            {searchOpen && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                handleCloseSearch={handleCloseSearch}
                style={{ marginRight: theme.spacing(1), height: 36 }}
              />
            )}
            {!searchOpen && (
              <Tooltip title="Search table data">
                <span>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={handleOpenSearch}
                    className="icon-button__backgrounded"
                    style={{
                      padding: theme.spacing(0.5),
                      borderRadius: 5,
                      marginRight: theme.spacing(1),
                      height: 36,
                      width: 36
                    }}
                  >
                    <SearchIcon
                      fontSize="small"
                      disabled={loading || submissionsLoading ? true : false}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Table Settings">
              <span>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={(event) => {
                    toggleDrawer(
                      {
                        open: true,
                        submission: null,
                        component: <SettingsDrawer instance={instance} />
                      },
                      event
                    );
                  }}
                  className="icon-button__backgrounded"
                  style={{
                    padding: theme.spacing(0.5),
                    borderRadius: 5,
                    marginRight: theme.spacing(1),
                    height: 36,
                    width: 36
                  }}
                >
                  <SettingsOutlinedIcon
                    fontSize="small"
                    disabled={loading || submissionsLoading ? true : false}
                  />
                </IconButton>
              </span>
            </Tooltip>
            {!presentationMode && (
              <Tooltip title="Service Delivery Dashboard">
                <span>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => {
                      history.push(`/variner/reporting/${id}`);
                    }}
                    className="icon-button__backgrounded"
                    style={{
                      padding: theme.spacing(0.5),
                      borderRadius: 5,
                      marginRight: theme.spacing(1),
                      height: 36,
                      width: 36
                    }}
                  >
                    <BarChartOutlinedIcon
                      fontSize="small"
                      disabled={loading || submissionsLoading ? true : false}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Generate Slate or Export">
              <span>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={(event) =>
                    handleOpenGenerateSlate(event.currentTarget, sortedSubmissions)
                  }
                  className="icon-button__backgrounded"
                  style={{
                    padding: theme.spacing(0.5),
                    borderRadius: 5,
                    height: 36,
                    width: 36
                  }}
                  disabled={loading || submissionsLoading ? true : false}
                >
                  <LaunchIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            <Popover
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <Box m={2}>
                <List>
                  <MenuItem disabled={!numSelected || numSelected === 0}>
                    <CSVLink
                      onClick={() => setAnchorEl(null)}
                      disabled={!numSelected || numSelected === 0}
                      style={{
                        width: "100%",
                        height: "100%",
                        color: colors.black,
                        textDecoration: "none"
                      }}
                      data={compiledCsvData}
                      filename={`job_${currentJob.id}-${activeStatusGroup.replace(
                        " ",
                        "_"
                      )}_submissions.csv`}
                      target="_blank"
                    >
                      Export CSV
                    </CSVLink>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setShowDialog(true);
                    }}
                  >
                    Generate Candidate Slate
                  </MenuItem>
                </List>
              </Box>
            </Popover>
          </Box>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default TableToolbar;
